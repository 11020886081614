const Contact = () => {
    return (
        <div className="contact">
            <span className="material-symbols-outlined">mail </span>
            <a href="mailto:shoaib.mandavia@yahoo.com">
            shoaib.mandavia@yahoo.com</a>
        </div>
    )
}

export default Contact;