const Skills = () => {
    return (
        <div className="skills">
            <h2 className="skills__heading">Skills</h2>
            <div className="skills__experience">
                <div className="skills__text">
                    Skills, I have working experience.
                </div>
                <ul className="skills__experience--list">
                    <li className="skill">
                        <i className="devicon-html5-plain-wordmark colored"></i>
                        <span className="skill__text">HTML 5</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-css3-plain-wordmark colored"></i>
                        <span className="skill__text">CSS3</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-javascript-plain colored"></i>
                        <span className="skill__text">JavaScript</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-sass-original colored"></i>
                        <span className="skill__text">Sass</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-tailwindcss-plain colored"></i>
                        <span className="skill__text">TailwindCSS</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-bootstrap-plain colored"></i>
                        <span className="skill__text">Bootstrap</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-react-original colored"></i>
                        <span className="skill__text">React</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-wordpress-plain colored"></i>
                        <span className="skill__text">Wordpress Development</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-php-plain colored"></i>
                        <span className="skill__text">PHP</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-python-plain colored"></i>
                        <span className="skill__text">Python</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-laravel-plain colored"></i>
                        <span className="skill__text">Laravel</span>
                    </li>
                </ul>
            </div>
                <hr className="skills__divider" />
            <div className="skills__know">
                <div className="skills__text">
                    Skills, I learned in school or my own, but no working experience.
                </div>
                <ul className="skills__know--list">
                    <li className="skill">
                        <i className="devicon-react-original colored"></i>
                        <span className="skill__text">React Native</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-django-plain colored"></i>
                        <span className="skill__text">Django</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-angularjs-plain colored"></i>
                        <span className="skill__text">Angular</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-java-plain colored"></i>
                        <span className="skill__text">Java</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-dotnetcore-plain colored"></i>
                        <span className="skill__text">ASP.NET Core</span>
                    </li>
                    <li className="skill">
                        <i className="devicon-mysql-plain colored"></i>
                        <span className="skill__text">MySql</span>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Skills;