import './App.css';
import Header from './components/Header'
import About from './components/About'
import Projects from './components/Projects'
import Skills from './components/Skills'
import Timeline from './components/Timeline'
import Contact from './components/Contact'

function App() {
  return (
    <div className="App">
      <Header/>
      <About/>
      <Projects/>
      <Skills/>
      <Timeline
        timeline={[
          {'places':'Resulta','year':'2021 - Present','icon':'resulta-logo.svg','description': 'Working as a Junior Software Developer'},
          {'places':'Velsoft','year':'2020 - 2021','icon':'velsoft-logo.jpeg','description': 'Worked as a Web Developer'},
          {'places':'Nova Scotia Community College','year':'2018 - 2020','icon':'nscc-logo.jpeg','description': 'Studied IT Web Development'}
        ]}
      />
      <Contact/>
    </div>
  );
}

export default App;
