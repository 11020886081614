function Project({item, index}) {

    return (
        <div id={`project-${index}`} className="project">
                <a className='project__link' target='_blank' title={item.title} href={item.link}>
                    <img className="project__img" alt={item.title} src={require(`./../assets/${item.img}.jpeg`)}/>
                    <div className="project__text">
                        <h3 className="project__title">{item.title}</h3>
                        <p className="project__description">{item.description}</p>
                        {item.skills.map((skill, key)=> (
                            <span id={key} className="project__skill">{skill}</span>
                            ))}
                    </div>
                </a>
            </div>
    )

}

export default Project;