function Header() {
    return (
        <header className="header__container">
            {/* <img className="header__logo" src="#" alt="logo"/> */}
            <div className="header__logo">
                <span className="material-symbols-outlined">laptop_mac</span>
            </div>
            <h1 className="header__name">Shoaib Khalid</h1>
            <hr/>
            <p className="header__position">Web Programmer</p>
        </header>
    )
}

export default Header;
