import profile from './../assets/shoaib_khalid.jpeg'

function About() {
    return (
        <div className="about">
            <h2 className="about__heading">About Me</h2>
            <div className="about__wrapper">
                <div className="about__photo">
                    <img src={profile} alt="Shoaib Khalid"/>
                </div>
                <div className="about__introduction">
                    <div className='about__card'>
                    <div className="about__card-header">
                        <span></span>                     
                        <span></span>  
                        <span></span>
                    </div>
                    <div className='about__text'>
                        <p>Hi!</p>
                        <p>👋 My name is Shoaib Khalid. I am a Web Developer working for Resulta.</p>
                        <p>I came to Canada 🇨🇦 in 2018 and loved this multicultural and vibrant country. I went through the experience of being a newcomer in a new country, and I am still learning every day.</p>
                        <p>I have a diverse set of skills, ranging from design to HTML+CSS+JAVASCRIPT, all the way to React.js, PHP, and WordPress Development. Picking up a new framework or language isn't a problem. I have been programming since 2020, and I'm comfortable developing on the front or backend and setting up or managing infrastructure.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;