import { useState, useEffect, useRef } from "react";

const Timeline = ({timeline}) => {

    let myRef = useRef(null);
    const [currentHeight, setCurrentHeight] = useState('100px');
    
    window.addEventListener('resize', function() {
        console.log(myRef.current.offsetHeight)
        setCurrentHeight(myRef.current.offsetHeight);

    });

    
    function onScrolHeight(e) {
        console.log('hello')

    }

    return (
        <div className="timeline">
            <h2>Timeline</h2>

            <div style={{height: {currentHeight} + 'px'}} className="timeline__verticalline"/>
            <div ref={myRef} className="timeline__wrapper">

            {timeline.map((item, index) => (
            <div onResize={onScrolHeight} key={index} className="timeline__container">

                <div className="timeline__icon">    
                    <img src={require(`./../assets/${item.icon}`)} alt={item.places}/>
                </div>

                
                <div className="timeline__name">
                    {item.places}
                </div>
                <div className="timeline__year">
                    {item.year}
                </div>
                <div className="timeline__description">
                    {item.description}
                </div>
            
            </div>

            ))}
</div>
        </div>
    )
}

export default Timeline;